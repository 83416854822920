import { useQuery } from '@tanstack/react-query';
import request, { gql } from 'graphql-request';

import { GraphQLEndpoint } from './endpoint';
import { PartnerData } from './types/graphql';

export const query = gql`
  query Partners($partnerId: String!) {
    content {
      partnerData(partnerId: $partnerId) {
        partnerId
        productSelections
      }
    }
  }
`;

export function withQueryKey(req: { partnerId: string }) {
  return {
    key: ['partnerData', req.partnerId],
    fetch: async () => {
      const data: any = await request(GraphQLEndpoint, query, req);
      return data.content.partnerData as PartnerData;
    },
  };
}

export function usePartnerData(partnerId: string) {
  const { key, fetch } = withQueryKey({
    partnerId,
  });

  return useQuery({
    queryKey: key,
    queryFn: fetch,
    enabled: !!partnerId,
  });
}
