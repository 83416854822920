import {
  BodyLinkV3 as BodyLink,
  ModalV3 as Modal,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { PropsWithChildren, useState } from 'react';

export const SizeGuide = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <p className="text-blue-100">
        <BodyLink color="inherit" onClick={() => setModalOpen(true)}>
          Size Guide
        </BodyLink>
      </p>

      <Modal
        isOpen={modalOpen}
        onOpenChange={() => setModalOpen(false)}
        rounded
        size="small"
        variant="lightest"
      >
        <div className="flex flex-col gap-6">
          <Typography Element="h2" variant="h4">
            Oura Ring sizing tips
          </Typography>

          <Section title="Choose your finger">
            <p>
              Your Oura Ring can be worn on any finger. We recommend the index,
              middle, or ring finger for optimal performance.
            </p>
          </Section>

          <Section title="Test the fit">
            <p>
              Your Oura Ring can be worn on any finger. We recommend the index,
              middle, or ring finger for optimal performance.
            </p>

            <p>
              Take your time to find the perfect fit, we recommend wearing your
              sizing ring for at least 24 hours before confirming your size.
            </p>
          </Section>
        </div>
      </Modal>
    </>
  );
};

export default SizeGuide;

function Section({
  title,
  children,
}: PropsWithChildren<{ readonly title: string }>): JSX.Element {
  return (
    <section className="flex flex-col gap-2">
      <Typography Element="h3" variant="h5" color="gray">
        {title}
      </Typography>

      {children}
    </section>
  );
}
