import { type ReactNode, useState } from 'react';
import ReactCarousel from 'react-multi-carousel';

import { RESPONSIVE_DATA } from '@/app/const';

import CarouselButtons from './CarouselButtons';
import CarouselImage, { ProductImageProps } from './CarouselImage';
import { ChangeSlide, ProductCarouselImage } from './types';

interface ProductImagesProps {
  readonly images?: ProductCarouselImage[];
}

export const ProductCarousel = ({ images }: ProductImagesProps): ReactNode => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  if (!images?.length) return null;

  const carouselImages = images.map(({ alt, originalSrc }) => ({
    alt,
    src: originalSrc,
  }));

  const handleChange: ChangeSlide = (nextSlide) => setActiveSlide(nextSlide);

  return (
    <ReactCarousel
      arrows={false}
      beforeChange={handleChange}
      containerClass="flex gap-4 overflow-hidden"
      customButtonGroup={
        <CarouselButtons images={carouselImages} onChange={handleChange} />
      }
      renderButtonGroupOutside={true}
      responsive={RESPONSIVE_DATA}
      sliderClass="max-h-96 bg-white md:bg-transparent"
    >
      {carouselImages.map(({ alt, src }: ProductImageProps, idx) => (
        <CarouselImage
          key={src}
          active={activeSlide === idx}
          alt={alt}
          priority={idx === 0}
          src={src}
        />
      ))}
    </ReactCarousel>
  );
};

export default ProductCarousel;
