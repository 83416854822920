import { useAppSelector } from '@/lib/hooks';
import { Order } from '@/types';

const useOrderData = (): Order => {
  const order = useAppSelector((state) => state.order);

  return order;
};

export default useOrderData;
