import ImgixClient from '@imgix/js-core';
import { useEnvContext } from 'next-runtime-env';

import type { GetImgixSrcParams } from '../types';
import getSrcUrl from './getSrcUrl';

const getImgixSrc = ({
  src,
  width,
  quality = 75,
  cdnOptions = {},
}: GetImgixSrcParams): string => {
  const {
    NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN,
    NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN,
  } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEnvContext();
  const imgixClient = new ImgixClient({
    domain: NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN!,
    secureURLToken: NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN!,
  });
  const srcUrl = getSrcUrl(src);

  return imgixClient.buildURL(srcUrl, {
    fm: 'webp',
    w: width,
    q: quality,
    ...cdnOptions,
  });
};

export default getImgixSrc;
