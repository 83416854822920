'use client';

import {
  GridItem,
  LayoutGrid,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { type ReactNode } from 'react';

import { box } from '@/app/styles/ui';
import type { Address, LineItem } from '@/types';

import { Loading, OrderInfo, OrderItems, SectionHeading, Svg } from '../../ui';
import SummaryNext from './SummaryNext';

interface SummaryLayoutProps {
  readonly address: Address;
  readonly email: string;
  readonly lineItems: LineItem[];
  readonly isLoading?: boolean;
  readonly showNext?: boolean;
  readonly help?: ReactNode;
}

const SummaryLayout = ({
  address,
  email,
  help,
  lineItems,
  isLoading,
  showNext,
}: SummaryLayoutProps): JSX.Element => {
  const styles = {
    box: cx(box()),
    orderInfoSection: cx('w-full flex flex-col gap-1 md:w-1/2'),
  };

  return (
    <LayoutGrid className="w-full pt-10 lg:pt-20" rowGap={24}>
      <GridItem
        colStart={{ sm: 'main', xl: 3 }}
        colEnd={{ sm: 'main', xl: 23 }}
        className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 md:mb-4 md:px-8 md:py-6 lg:mb-8"
      >
        <div className="shrink-0">
          <Svg name="green-circle-check" />
        </div>

        <SectionHeading Element="h2">Thank you for your order</SectionHeading>
      </GridItem>

      <GridItem
        colStart={{ sm: 'main', xl: 3 }}
        colEnd={{ sm: 'main', md: 10 }}
      >
        <Typography variant="h4">
          Our mission is to make health a daily practice.
        </Typography>
      </GridItem>

      <GridItem
        colStart={{ sm: 'main', md: 11 }}
        colEnd={{ sm: 'main', xl: 23 }}
        id="section-order-details"
        className="flex flex-col justify-between gap-4"
      >
        <Typography variant="body-large">
          Welcome to the Oura member community! Whether you’re striving for
          better sleep or more balance, Oura is here to support your personal
          health journey. Stay tuned for email updates on your ring shipment.
        </Typography>

        {help && <div>{help}</div>}
      </GridItem>

      <GridItem
        className="mt-4 border-t border-solid border-gray-300 md:mt-4 lg:mt-8"
        colStart={{ sm: 'main', xl: 3 }}
        colEnd={{ sm: 'main', xl: 23 }}
      >
        {' '}
      </GridItem>

      <GridItem
        className="flex flex-col gap-y-4"
        colStart={{ sm: 'main', xl: 3 }}
        colEnd={{ sm: 'main', md: 10 }}
      >
        <OrderInfo address={address} className="h-full" email={email} />
      </GridItem>

      <GridItem
        colStart={{ sm: 'main', md: 11 }}
        colEnd={{ sm: 'main', xl: 23 }}
        id="section-order-details"
      >
        <div className={cx(styles.box, 'h-full flex flex-col justify-stretch')}>
          {isLoading ? <Loading /> : <OrderItems lineItems={lineItems} />}
        </div>
      </GridItem>

      {showNext && (
        <GridItem
          colStart={{ sm: 'main', xl: 3 }}
          colEnd={{ sm: 'main', xl: 23 }}
          id="section-order-details"
        >
          <Typography
            Element="h3"
            className="mb-10 border-b border-gray-300 pb-2"
            variant="h4"
          >
            What's next?
          </Typography>

          <SummaryNext />
        </GridItem>
      )}
    </LayoutGrid>
  );
};

export default SummaryLayout;
