// export const auth = {
//   clientId: process.env.AUTH_CLIENT_ID,
//   redirectUri: process.env.AUTH_REDIRECT_URI,
//   scope: process.env.AUTH_SCOPE,
//   authorizationEndpoint: process.env.AUTH_ENDPOINT,
//   tokenEndpoint: process.env.AUTH_TOKEN_ENDPOINT,
// };

export const auth = {
  clientId: 'ecomm_eop_haapi_client',
  redirectUri: 'https://essence.ourasandbox.com',
  scope: 'openid',
  authorizationEndpoint: 'http://localhost:8443/oauth/v2/ohub/oauth-authorize',
  tokenEndpoint: 'http://localhost:8443/oauth/v2/ohub/oauth-token',
};
