import { isBrowser } from './isBrowser';

// Create a new cookie
export function setCookie(
  name: string,
  value: string | string[],
  minutes: number,
) {
  if (isBrowser()) {
    let expires = '';
    const date = new Date();
    const now = new Date();
    date.setTime(now.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
    return;
  }
  return null;
}

// Get/check an existing cookie
//
export function getCookie(name: string) {
  if (isBrowser()) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

// Erase the cookie
//
export function eraseCookie(name: string) {
  if (isBrowser()) {
    document.cookie = name + '=; path=/; Max-Age=-99999999;';
    return;
  }
  return null;
}

export default {
  setCookie,
  getCookie,
  eraseCookie,
};
