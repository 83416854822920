import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import {
  EnterpriseNamespaceValidateEmailArgs,
  ValidateEmailResponse,
} from './types/graphql';
import queryClient from './utils/queryClient';

export const query = gql`
  query ValidateEmail($email: String!) {
    enterprise {
      validateEmail(email: $email) {
        status
      }
    }
  }
`;

export function withQueryKey(req: { email: string }) {
  return {
    key: ['validateEmail', req.email],
    fetch: async () => {
      //TODO: Implement once backend is completed. Mock data for now.
      //const data: any = await request(GraphQLEndpoint, query, req);
      //return data.enterprise.validateEmail as ValidateEmailResponse;
      return { status: true };
    },
  };
}

export function useValidateEmail(email: string) {
  const { key, fetch } = withQueryKey({
    email,
  });

  return useQuery({
    queryKey: key,
    queryFn: fetch,
    enabled: !!email,
  });
}

export async function validateEmail(
  req: EnterpriseNamespaceValidateEmailArgs,
): Promise<ValidateEmailResponse> {
  const { key, fetch } = withQueryKey(req);

  return await queryClient.fetchQuery(key, fetch);
}
