import type { ImageLoaderProps } from 'next/image';

import type { CdnOptions } from './types';
import getImgixSrc from './utils/getImgixSrc';

/**
 * This is a wrapper function around getImgixSrc to allow for easier use of
 * the loader prop on the Image component. Most of the time you won't need to
 * use the loader prop but if you ever need to provide extra params for an Imgix
 * request via cdnOptions, this is what you'll need to use.
 */
const imgixLoader = (
  img: ImageLoaderProps,
  cdnOptions: CdnOptions = {},
): string => {
  return getImgixSrc({ ...img, cdnOptions });
};

export default imgixLoader;
