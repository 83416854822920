/* eslint @typescript-eslint/prefer-nullish-coalescing: 1 -- Do not want to break existing code by switching to nullish coalesce blidnly */
import type { HandlerType, StatusType } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import isError from 'lodash/isError';

export type LogModule = 'app' | 'auth' | 'metrics' | 'order';

interface JsonFormatError extends Error {
  [x: string]: unknown;
}

const serializeError = ({
  name,
  stack,
  message,
  cause,
  ...rest
}: JsonFormatError) => ({
  name,
  stack,
  message,
  cause,
  ...rest,
});

const serializeData = (
  data:
    | {
        [key: string]: any;
      }
    | undefined,
): { [key: string]: any } | undefined => {
  if (!data) return data;

  return Object.entries(data).reduce(
    (acc, cur) => ({
      ...acc,
      [cur[0]]: isError(cur[1])
        ? serializeError(cur[1] as JsonFormatError)
        : cur[1],
    }),
    {},
  );
};

const isHandlerType = (value: string): value is HandlerType => {
  const allowedValues: string[] = ['console', 'http', 'silent'];
  return allowedValues.indexOf(value) !== -1;
};

const isStatusType = (value: string): value is StatusType => {
  const allowedValues: string[] = ['debug', 'error', 'info', 'warn'];
  return allowedValues.indexOf(value) !== -1;
};

const getHandler = (): HandlerType => {
  // Check process.env for override
  const logHandler = process.env.NEXT_PUBLIC_DD_LOG_HANDLER!;
  if (isHandlerType(logHandler)) {
    return logHandler;
  }
  // Return http by default
  return 'http';
};

const getLevel = (): StatusType => {
  // Check process.env for override
  const logLevel = process.env.NEXT_PUBLIC_LOG_LEVEL!;
  if (isStatusType(logLevel)) {
    return logLevel;
  }
  // Return debug by default
  return 'debug';
};

datadogLogs.createLogger(process.env.PROJECT_NAME!, {
  handler: getHandler(),
  level: getLevel(),
  context: {
    env: process.env.NEXT_PUBLIC_OURA_ENV,
    service: process.env.PROJECT_NAME!,
  },
});

// Client-side logger
export default async function logToDatadog(
  module: LogModule,
  message: string,

  data: { [key: string]: any } = {},
  level: StatusType = 'error',
  logger = datadogLogs.getLogger(process.env.PROJECT_NAME!),
): Promise<void> {
  logger?.log(
    message,
    {
      payload: serializeData(data),
      module,
    },
    level,
  );
}
