import Image from 'next/image';
import { type ReactNode } from 'react';

import getSvg from './getSvg';
import type { SvgName } from './types';

export interface SvgProps {
  readonly name: SvgName;
}

const Svg = ({ name }: SvgProps): ReactNode => {
  const src = getSvg(name);

  if (!src) return null;
  return <Image priority src={src} alt="" />;
};

export default Svg;
