import { QueryClient } from 'react-query';

// Use a longer staleTime (5 minutes) on the client to reduce network traffic. The user can
// always refresh their browser to clear this cache.
//
// On the server, use a shorter staleTime (5 seconds) because network requests are faster and
// we always want the latest data.

const defaultStaleTime =
  typeof window === 'undefined' ? 1000 * 5 : 1000 * 60 * 5;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: defaultStaleTime,
    },
  },
});

export default queryClient;
