export enum AuthorizationStep {
  COMPLETE = 'authorization-complete',
  LOADING = 'loading',
  PROCESS_RESULT = 'process-result',
  REDIRECT = 'redirection-step',
  UNKNOWN = 'unknown-step',
  ERROR = 'error-step',
  AUTHENTICATION = 'authentication-step',
  CONTINUE_REDIRECT = 'continue-redirect-step',
  REGISTRATION = 'registation-step',
  POLLING = 'polling-step',
  CONTINUE_CONTINUE = 'continue-continue-step',
  EXTERNAL_BROWSER_LAUNCH = 'external-browser-launch',
}
