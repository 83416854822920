import {
  BoxedDeliveryIcon,
  BoxedFingerIcon,
  BoxedRingOr4Icon,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';

import { SectionHeading } from '@/app/components/ui';

interface Step {
  Icon: typeof BoxedDeliveryIcon;
  title: string;
  content: string;
}

const STEPS: Step[] = [
  {
    Icon: BoxedDeliveryIcon,
    title: 'Sizing Kit Delivery',
    content:
      'Expect to receive your sizing kit within 3 to 9 days. This kit includes sample ring sizers to help find your ideal fit.',
  },
  {
    Icon: BoxedFingerIcon,
    title: 'Find Your Size',
    content:
      'Test the sample rings on your preferred finger. Your Oura Ring can be worn on any finger. We recommend the index, middle, or ring finger for optimal performance.',
  },
  {
    Icon: BoxedRingOr4Icon,
    title: 'Get Your Oura Ring',
    content:
      "Confirm your size by visiting the link on the sizing kit's packaging to complete your order.",
  },
];

const SummaryNext = () => {
  return (
    <div className="flex flex-col gap-14 md:flex-row">
      {STEPS.map(({ Icon, title, content }, i) => (
        <div key={title} className="flex w-full shrink flex-col gap-4">
          <Icon />
          <SectionHeading Element="h4" weight="bold">
            Step {i + 1}: {title}
          </SectionHeading>

          <Typography>{content}</Typography>
        </div>
      ))}
    </div>
  );
};

export default SummaryNext;
