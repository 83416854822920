import { cx } from 'class-variance-authority';

import { optionButton } from '../styles';

export interface SizeOptionsProps {
  readonly error?: string;
  readonly onChange: (value: string) => void;
  readonly options: {
    label: string;
    value: string;
  }[];
  readonly selected: string;
}

export const SizeOptions = ({
  error,
  onChange,
  options,
  selected,
}: SizeOptionsProps): JSX.Element => {
  return (
    <ul
      aria-errormessage={error}
      aria-invalid={Boolean(error)}
      aria-labelledby="size-options-heading"
      aria-required="true"
      className="flex flex-wrap gap-4"
      role="radiogroup"
    >
      {options?.map(({ label, value }) => {
        const checked = selected === value;

        return (
          <li key={value} className="max-w-1/2 w-24 min-w-24 flex-1">
            <label className={cx(optionButton({ checked }), 'justify-center')}>
              <input
                checked={checked}
                className="sr-only"
                name="size"
                type="radio"
                value={value}
                onChange={() => onChange(value)}
              />

              {label}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default SizeOptions;
