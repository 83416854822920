import { cva } from 'class-variance-authority';

export const optionButton = cva(
  [
    'h-16 w-full overflow-hidden',
    'rounded-lg border border-solid',
    'flex items-center',
    'hover:border-white hover:bg-white',
  ],
  {
    variants: {
      checked: {
        true: 'border-gray-400 bg-white',
        false: 'border-gray-300 bg-gray-300',
      },
    },
  },
);
