import type { Address } from '@/types';

interface AddressDisplayProps {
  readonly address: Partial<Address>;
  readonly className?: string;
  readonly 'data-testid'?: string;
}

const AddressDisplay = ({
  address: fullAddress,
  className,
  'data-testid': dataTestId = 'address-display',
}: AddressDisplayProps): JSX.Element => {
  const {
    country,
    fname,
    lname,
    email,
    address,
    address2,
    city,
    state,
    postal,
    phone,
  } = fullAddress;

  return (
    <ol
      className={className}
      data-testid={dataTestId}
      data-fname={fname}
      data-lname={lname}
      data-email={email}
      data-address={address}
      data-address2={address2}
      data-city={city}
      data-state={state}
      data-postal={postal}
      data-country={country}
      data-phone={phone}
    >
      <li
        data-testid={`${dataTestId}-name`}
        hidden={[fname, lname].every((val) => !val)}
      >
        {fname} {lname}
      </li>

      <li data-testid={`${dataTestId}-email`} hidden={!email}>
        {email}
      </li>

      <li data-testid={`${dataTestId}-address`} hidden={!address}>
        {address}
      </li>

      <li data-testid={`${dataTestId}-address2`} hidden={!address2}>
        {address2}
      </li>

      {(city || state || postal) && (
        <li
          data-testid={`${dataTestId}-city-state-zip`}
          hidden={[city, state, postal].every((val) => !val)}
        >
          {city}
          {state ? `, ${state} ` : ' '}
          {postal}
        </li>
      )}

      <li data-testid={`${dataTestId}-country`} hidden={!country}>
        {country}
      </li>

      <li data-testid={`${dataTestId}-phone`} hidden={!phone}>
        {phone}
      </li>
    </ol>
  );
};

export default AddressDisplay;
