import { cva } from 'class-variance-authority';

export const logo = cva('', {
  variants: {
    logo: {
      essence: 'w-12 lg:w-16',
      undefined: '',
    },
  },
});
