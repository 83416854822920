import { cx } from 'class-variance-authority';

import { box } from '@/app/styles/ui';
import type { Address } from '@/types';

import AddressDisplay from '../AddressDisplay';
import SectionHeading from '../SectionHeading';

interface OrderInfoProps {
  readonly address: Address;
  readonly email: string;
  readonly className?: string;
}

const OrderInfo = ({
  address,
  email,
  className,
}: OrderInfoProps): JSX.Element => {
  return (
    <div className={cx(box(), 'flex gap-4 flex-wrap', className)}>
      <section className="flex flex-1 flex-col gap-1">
        <SectionHeading Element="h3" weight="bold">
          Oura Account Email
        </SectionHeading>

        <p>{email}</p>
      </section>

      <section className="flex flex-1 flex-col gap-1">
        <SectionHeading Element="h3" weight="bold">
          Delivery Address
        </SectionHeading>

        <AddressDisplay address={address} />
      </section>
    </div>
  );
};

export default OrderInfo;
