'use client';

import { Button, FormControl } from '@jouzen/ecom-components';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Yup from 'yup';

import { useRouter } from '@/i18n/routing';
import { metricIncrement } from '@/utils/reportMetrics';

import SubmitButton from '../SubmitButton';
import { CheckStatus, LOGIN_METRIC_NAME, MetricStep } from './const';

interface OTPFormProps {
  readonly setCheckStatus: Dispatch<SetStateAction<CheckStatus | null>>;
  readonly isLoading: boolean;
  readonly step: any;
  readonly submitForm: (
    formState: null | undefined,
    url: any,
    method: string | undefined,
  ) => Promise<void>;
  readonly clickLink: (url: string) => Promise<void>;
  readonly error?: string | null;
  readonly partner: string;
}

const AUTH_OFF = process.env.NEXT_PUBLIC_AUTH_OFF === 'true';

const OTPForm = ({
  setCheckStatus,
  isLoading,
  step,
  submitForm,
  clickLink,
  error,
  partner,
}: OTPFormProps): JSX.Element => {
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const { push } = useRouter();

  const resendCode = async () => {
    setResendLoading(true);
    if (step?.haapiResponse?.links[0].title.includes('Resend')) {
      await clickLink(step?.haapiResponse?.links[0].href)
        .then(async () => {
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.OTP_RESEND_CODE,
            partner,
          });
          setResendLoading(false);
        })
        .catch(() => {
          setCheckStatus(CheckStatus.ERROR);
        });
    }
  };

  const handleSubmit = async (values: any) => {
    await metricIncrement(LOGIN_METRIC_NAME, {
      step: MetricStep.OTP_SUBMITTED,
      partner,
    });
    if (AUTH_OFF) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.OTP_SUCCESS,
        partner,
      });
      push('/select');
    } else {
      const { model } = step.haapiResponse.actions[0];

      await submitForm(values, model.href, model.method).catch(
        async (error) => {
          setCheckStatus(CheckStatus.ERROR);
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.OTP_ERROR,
            partner,
            error,
          });
        },
      );
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: getFormValidationSchema,
    initialValues: {
      otp: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <form
      className="flex w-full flex-col gap-2"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <FormControl
        className="w-full"
        control="TextInput"
        errorMessage={formik.errors?.otp || error}
        label="Sign-in Code"
        maxLength={80}
        name="otp"
        onChange={formik.handleChange}
        required
        value={formik.values?.otp}
        disabled={isLoading}
      />

      <SubmitButton loading={isLoading} full type="submit">
        Submit
      </SubmitButton>

      <Button loading={resendLoading} onClick={() => resendCode()} type="reset">
        Resend Code
      </Button>
    </form>
  );
};

export default OTPForm;

function getFormValidationSchema() {
  return Yup.object().shape({
    otp: Yup.string()
      .trim()
      .required(() => 'Required')
      .max(60, () => 'Max 6 characters'),
  });
}
