'use client';

import { GridItem, LayoutGrid } from '@jouzen/ecom-components';
import { PropsWithChildren } from 'react';

import { Image } from '@/app/components/ui';

import PageHeader, { type PageHeaderProps } from './PageHeader';

interface Props {
  readonly eyebrow: PageHeaderProps['eyebrow'];
  readonly imageSrc: string;
  readonly note?: PageHeaderProps['note'];
  readonly title: PageHeaderProps['title'];
}

export const StepLayout = ({
  children,
  title,
  eyebrow,
  imageSrc,
  note,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <LayoutGrid className="w-full md:pt-10 lg:pt-20" rowGap={40}>
      <GridItem
        rowStart={{ sm: 2, md: 1 }}
        rowEnd={{ sm: 3, md: 2 }}
        colStart={{ sm: 'main', md: 13 }}
        colEnd={{ sm: 'main', xl: 23 }}
        className="flex flex-col gap-y-4"
      >
        <PageHeader eyebrow={eyebrow} title={title} note={note} />

        {children}
      </GridItem>

      {imageSrc && (
        <GridItem
          rowStart={{ sm: 1 }}
          rowEnd={{ sm: 2 }}
          colStart={{ sm: 'full', md: 'main', xl: 3 }}
          colEnd={{ sm: 'full', md: 12 }}
          className="hidden sm:block"
        >
          <div className="flex max-h-64 items-center justify-center overflow-hidden md:aspect-4/3 md:max-h-none md:rounded-lg">
            <Image
              src={imageSrc}
              width={600}
              height={600}
              className="aspect-2/1 min-h-full min-w-full object-cover md:aspect-auto"
              priority
            />
          </div>
        </GridItem>
      )}
    </LayoutGrid>
  );
};

export default StepLayout;
