'use client';

import {
  ButtonV3 as Button,
  ButtonV3Props as ButtonProps,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { MouseEventHandler, useState } from 'react';

interface SubmitButtonProps extends ButtonProps {
  readonly full?: boolean;
}

export const SubmitButton = ({
  children,
  className,
  full,
  loading,
  onClick,
  ...props
}: SubmitButtonProps) => {
  const [working, setWorking] = useState(false);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (loading || working) return;

    setWorking(true);

    if (typeof onClick === 'function') {
      onClick(e);
    }

    setWorking(false);
  };

  return (
    <Button
      data-testid="button-submit"
      {...props}
      className={cx(
        {
          'self-center md:self-end': !full,
          'w-full': full,
        },
        className,
      )}
      loading={loading || working}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
