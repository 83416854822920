'use client';

import {
  GridItem,
  LayoutGrid,
  Oura as OuraLogo,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';

import { type LogoName, Svg } from '../../ui';
import { logo as logoStyles } from './styles';

interface GlobalHeaderProps {
  readonly logo?: LogoName;
}

const GlobalHeader = ({ logo }: GlobalHeaderProps) => {
  const styles = {
    logo: logoStyles({ logo }),
  };

  return (
    <LayoutGrid className="bg-white" data-testid="global-header">
      <GridItem
        className="flex h-14 items-center lg:h-20"
        colEnd={{ sm: 'main' }}
        colStart={{ sm: 'main' }}
      >
        <span className="flex h-8 items-center gap-4 lg:h-12">
          {logo ? (
            <>
              {/* partner logo */}
              <span className={styles.logo}>
                <Svg name={logo} />
              </span>

              {/* separator */}
              <span
                className="my-4 h-5 border-l border-solid border-gray-600 lg:h-6"
                aria-hidden="true"
              />
            </>
          ) : null}

          <span className={cx('w-16 lg:w-20')}>
            <OuraLogo />
          </span>
        </span>
      </GridItem>
    </LayoutGrid>
  );
};

export default GlobalHeader;
