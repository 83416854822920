import { useQuery } from '@tanstack/react-query';
import { gql, request } from 'graphql-request';

import { GraphQLEndpoint } from './endpoint';

export const query = gql`
  query Countries($countryCode: String) {
    content {
      countries(countryCode: $countryCode) {
        currency
        currencyForSubscription
        supportedCurrencies
        status
        membershipStatus
        countryCode
        shippingRate {
          description
          price
        }
        eu
        name
        regions {
          label
          value
          labelMsgKey
        }
        merchant {
          prod
          staging
        }
        disallowedProductSkus
        labelMsgKey
        enableForRma
        showNoAdditionalTaxesMessage
      }
    }
  }
`;

function withQueryKey(vars: { countryCode?: string } = {}) {
  return {
    key: ['Countries', { vars }],
    fetch: async () => {
      const data: any = await request(GraphQLEndpoint, query, vars);

      const byCountryCode = new Map();

      for (const country of data.content.countries || []) {
        byCountryCode.set(country?.countryCode, country);
      }

      return { countries: data, byCountryCode };
    },
  };
}

export function useCountry(countryCode: string) {
  const { key, fetch } = withQueryKey({ countryCode });
  const { data, isLoading, isError } = useQuery({
    queryKey: key,
    queryFn: fetch,
  });

  return {
    isLoading,
    isError,
    data: data?.byCountryCode && data.byCountryCode.get(countryCode),
  };
}
