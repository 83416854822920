import { BREAKPOINTS_V3 as BREAKPOINTS } from '@jouzen/ecom-components';
import type { ResponsiveType } from 'react-multi-carousel';

const RESPONSIVE_DATA: ResponsiveType = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: BREAKPOINTS.large,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: BREAKPOINTS.large,
      min: BREAKPOINTS.medium,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: BREAKPOINTS.medium,
      min: 0,
    },
    items: 1,
  },
};

export default RESPONSIVE_DATA;
