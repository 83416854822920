import {
  findProductVariant,
  getProductFinish,
  getProductVariantSize,
} from '@/app/utils';
import type { Product, ProductVariant } from '@/queries/types/graphql';

export interface ProductData {
  finish: string | undefined;
  product: Product | undefined;
  size: string | undefined;
  variant: ProductVariant | undefined;
}

const findProductDataBySku = (
  products: Product[],
  sku: string,
): ProductData => {
  const product = products.find((product) =>
    product.variants?.some((variant) => variant?.skuCode === sku),
  );
  const finish = getProductFinish(product!);
  const variant = findProductVariant(product!, sku);
  const size = getProductVariantSize(variant!);

  return {
    finish,
    product,
    size,
    variant,
  };
};

export default findProductDataBySku;
