import { useGeoContext } from '../contexts/GeoProvider';

export const SYMBOL_MAP = {
  EUR: '€',
  USD: '$',
};

export type SupportedCurrencyCode = keyof typeof SYMBOL_MAP;

export default function useCurrency() {
  const { countryData } = useGeoContext();
  const currencyCode = countryData?.currency as keyof typeof SYMBOL_MAP;
  const currencySymbol = SYMBOL_MAP[currencyCode];

  return {
    countryData,
    currencyCode,
    currencySymbol,
  };
}
