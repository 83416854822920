import {
  BodyLinkV3 as BodyLink,
  type BodyLinkV3Props as BodyLinkProps,
} from '@jouzen/ecom-components';

const PrivacyPolicyLink = ({
  children,
  ...props
}: BodyLinkProps): JSX.Element => {
  return (
    <BodyLink
      color="inherit"
      href="https://ouraring.com/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children ?? 'privacy policy'}
    </BodyLink>
  );
};

export default PrivacyPolicyLink;
