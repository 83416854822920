import { BodyLink, Typography } from '@jouzen/ecom-components';
import { useState } from 'react';

import { eraseCookie } from '@/utils/cookie';

import SubmitButton from '../SubmitButton';

const NotFound = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const resetEmailForm = () => {
    setLoading(true);
    eraseCookie('session');
    //needed to ensure the auth is reinstantiated
    window.location.replace('/');
  };
  return (
    <div className="flex w-full flex-col gap-2">
      <SubmitButton
        loading={loading}
        onClick={() => resetEmailForm()}
        full
        type="submit"
      >
        Try another email
      </SubmitButton>
      <div className="my-4 border-b border-solid border-gray-300 pb-4" />
      <div>
        <Typography weight="bold">Need help signing in? </Typography>
        <Typography>
          Contact Essence Healthcare today by phone or email.{' '}
        </Typography>

        <BodyLink>Member Customer Service</BodyLink>
      </div>
    </div>
  );
};
export default NotFound;
