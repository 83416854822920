import { usePartnerData } from '@/queries/Partners';
import { useProducts } from '@/queries/Products';
import { Product } from '@/queries/types/graphql';

export interface PartnerProductsData {
  isLoading: boolean;
  isError: boolean;
  data: Product[];
}

const usePartnerProducts = (partner: string): PartnerProductsData => {
  // Fetch productSelections for partner
  const {
    data: partnerData,
    isLoading: partnerDataLoading,
    isError: partnerDataError,
  } = usePartnerData(partner);

  const {
    data: productData,
    isLoading: productDataLoading,
    isError: productDataError,
  } = useProducts({ productType: 'Ring' });

  const productOptions = partnerData?.productSelections;

  return {
    isLoading: partnerDataLoading || productDataLoading,
    isError: productDataError || partnerDataError,
    data:
      productData?.filter((product) => productOptions?.includes(product.id)) ??
      [],
  };
};

export default usePartnerProducts;
