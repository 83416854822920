import { ImageProps } from 'next/image';

import essenceLogo from './img/essence-logo.svg';
import greenCircleCheck from './img/green-circle-check.svg';
import { SvgName } from './types';

const getSvg = (name: SvgName): ImageProps['src'] | null => {
  switch (name) {
    case 'essence':
      return essenceLogo;
    case 'green-circle-check':
      return greenCircleCheck;
    default:
      return null;
  }
};

export default getSvg;
