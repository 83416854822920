import CircularProgress from '@mui/material/CircularProgress';
import { cx } from 'class-variance-authority';

interface LoadingProps {
  readonly fullScreen?: boolean;
}

const Loading = ({ fullScreen }: LoadingProps): JSX.Element => {
  return (
    <div
      className={cx('flex items-center justify-center', {
        'h-screen w-screen': fullScreen, // fill screen
        'h-full w-full': !fullScreen, // fill container
      })}
    >
      <CircularProgress />
    </div>
  );
};

export default Loading;
