import { TypographyV3 as Typography } from '@jouzen/ecom-components';

import { Image, SectionHeading } from '@/app/components/ui';
import { LineItem } from '@/types';

export interface LineItemProps {
  readonly lineItem: LineItem;
}

const OrderLineItem = ({ lineItem }: LineItemProps): JSX.Element => {
  const { description, image, title } = lineItem;

  return (
    <div
      className="flex flex-col gap-2 md:flex-row md:items-center md:gap-5"
      data-testid="cart-line-item-product"
    >
      <div className="flex-none">
        {image?.src ? (
          <Image
            data-testid="product-image"
            src={image.src}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        ) : null}
      </div>

      <div className="flex-1">
        {title && (
          <SectionHeading Element="h3" weight="bold">
            {title}
          </SectionHeading>
        )}

        {description && <Typography>{description}</Typography>}
      </div>
    </div>
  );
};

export default OrderLineItem;
