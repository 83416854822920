'use client';

import { useLocale } from 'next-intl';
import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import { useCountry } from '@/queries/Countries';

export interface GeoProviderProps extends PropsWithChildren {
  readonly countryCode: string;
}

interface GeoContext {
  countryData: any;
  locale: string;
}

const Context = createContext<GeoContext | null>(null);

export const useGeoContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Did you forget to include GeoProvider in your tree?');
  }
  return ctx;
};

const GeoProvider = ({
  children,
  countryCode,
}: GeoProviderProps): JSX.Element => {
  const locale = useLocale();
  const { data } = useCountry(countryCode);
  const { data: usData } = useCountry('US');

  const countries = data?.countries;
  const usCountries = usData?.countries;

  const countryData = countries
    ? countries[0]
    : usCountries
      ? usCountries[0]
      : { countryCode: 'US', currency: 'USD', eu: false };

  return (
    <Context.Provider value={{ countryData: countryData!, locale }}>
      {children}
    </Context.Provider>
  );
};

export default GeoProvider;
