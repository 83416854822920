import { Product, ProductVariant } from '@/queries/types/graphql';
import { Order } from '@/types';

const findProductVariant = (
  product: Product,
  sku: Order['selectedSku'],
): ProductVariant | undefined => {
  return (
    product?.variants?.find((variant) => variant?.skuCode === sku) ?? undefined
  );
};

export default findProductVariant;
