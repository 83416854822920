import NextImage from 'next/image';
import { useTranslations } from 'next-intl';
import type { Ref } from 'react';
import { forwardRef } from 'react';

import imgixLoader from './imgixLoader';
import type { ImageProps } from './types';
import getSrcUrl from './utils/getSrcUrl';

const Image = forwardRef(function Image(
  {
    alt,
    format = 'jpg',
    localizedAlt,
    localizedSrc,
    src,
    ...props
  }: ImageProps,
  ref?: Ref<HTMLImageElement>,
): JSX.Element {
  const t = useTranslations();
  const _alt = localizedAlt ? t(localizedAlt) : (alt ?? '');
  const _src = localizedSrc ? t(localizedSrc) : (src ?? '');

  if (!_src) {
    throw new Error('No src provided for Image component.');
  }

  const srcUrl = getSrcUrl(_src, format);

  return (
    <NextImage
      alt={_alt}
      loader={imgixLoader}
      ref={ref}
      src={srcUrl}
      {...props}
    />
  );
});

export default Image;
