import {
  TypographyV3 as Typography,
  TypographyV3Props as TypographyProps,
} from '@jouzen/ecom-components';

const DEFAULT_PROPS: Partial<TypographyProps> = {
  Element: 'h3',
  variant: 'h5',
};

export const SectionHeading = ({
  children,
  ...rest
}: Partial<TypographyProps>) => {
  const props = { ...DEFAULT_PROPS, ...rest };
  return <Typography {...props}>{children}</Typography>;
};

export default SectionHeading;
