export const LOGIN_METRIC_NAME = 'login';

export enum CheckStatus {
  ERROR = 'error',
  ERROR_NOT_ELIGIBLE = 'error_not_eligible',
  SUCCESS_EMAIL = 'success_email',
  EXPIRED = 'expired',
}

export enum ApiEndpoint {
  // this might be removed / replaced for MOI..
  EMAIL = '/api/my-order/check-email',
}

export enum MetricStep {
  EMAIL_SUBMITTED = 'email_submitted',
  EMAIL_SUCCESS = 'email_success',
  EMAIL_ERROR = 'email_error',
  EMAIL_INELIGIBLE = 'email_ineligible',
  EMAIL_NOT_FOUND = 'email_not_found',
  OTP_SUBMITTED = 'otp_submitted',
  OTP_ERROR = 'otp_error',
  OTP_RESEND_CODE = 'otp_resend_code',
  OTP_SUCCESS = 'otp_success',
}
