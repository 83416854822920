import { BREAKPOINTS_V3 as BREAKPOINTS } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { type ReactNode } from 'react';

import { Image, type ImageProps } from '@/app/components/ui';

export interface ProductImageProps extends ImageProps {
  readonly active?: boolean;
}

const CarouselImage = ({
  active: isActive,
  alt,
  priority,
  src,
}: ProductImageProps): ReactNode => {
  const isMd = `(min-width: ${BREAKPOINTS.medium}px)`;

  const width = isMd ? 512 : 128;
  const height = width;

  return (
    <div
      className={cx(
        'h-full max-h-48 md:max-h-96',
        'bg-white rounded-lg overflow-hidden',
        'flex justify-center items-center',
        'transition-opacity duration-300',
        {
          'opacity-0': !isActive,
          'opacity-100': isActive,
        },
      )}
    >
      <Image
        key={alt}
        alt={alt}
        className="max-h-60 w-auto object-contain md:max-h-96"
        draggable="false"
        height={height}
        priority={priority}
        quality={65}
        src={src}
        width={width}
      />
    </div>
  );
};

export default CarouselImage;
