'use client';

import { ButtonV3 as Button, FormControl } from '@jouzen/ecom-components';
import { useFormik } from 'formik';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { emailAdded } from '@/lib/order/orderSlice';
import { metricIncrement } from '@/utils/reportMetrics';

import SubmitButton from '../SubmitButton';
import { CheckStatus, LOGIN_METRIC_NAME, MetricStep } from './const';

interface EmailFormProps {
  readonly setCheckStatus: Dispatch<SetStateAction<CheckStatus | null>>;
  readonly shouldResetForm: boolean;
  readonly step: any;
  readonly submitForm: (
    formState: null | undefined,
    url: any,
    method: string | undefined,
  ) => Promise<void>;
  readonly error?: string | null;
  readonly partner: string;
  readonly isLoading: boolean;
}

const AUTH_OFF = process.env.NEXT_PUBLIC_AUTH_OFF === 'true';

const EmailForm = ({
  setCheckStatus,
  shouldResetForm,
  step,
  submitForm,
  error,
  partner,
  isLoading,
}: EmailFormProps): JSX.Element => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    await metricIncrement(LOGIN_METRIC_NAME, {
      step: MetricStep.EMAIL_SUBMITTED,
      partner,
    });
    if (AUTH_OFF) {
      dispatch(emailAdded(values?.username));
      setCheckStatus(CheckStatus.SUCCESS_EMAIL);
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_SUCCESS,
        partner,
      });
    } else {
      const { model } = step.haapiResponse.actions[0];
      await submitForm(values, model.href, model.method)
        .then(async () => {
          setCheckStatus(CheckStatus.SUCCESS_EMAIL);
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.EMAIL_SUCCESS,
            partner,
          });
        })
        .catch(async (error) => {
          await metricIncrement(LOGIN_METRIC_NAME, {
            step: MetricStep.EMAIL_ERROR,
            partner,
            error,
          });
          setCheckStatus(CheckStatus.ERROR);
        });
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: getFormValidationSchema,
    initialValues: {
      username: '',
    },
    onSubmit: handleSubmit,
  });

  const handleResetForm = () => {
    setCheckStatus(null);
    formik.resetForm();
  };

  if (shouldResetForm) {
    return (
      <Button
        variant="outlined-dark"
        data-testid="button-reset-form"
        onClick={handleResetForm}
      >
        {t('my_account_another_email')}
      </Button>
    );
  }

  return (
    <form
      className="flex w-full flex-col gap-2"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <FormControl
        className="w-full"
        control="TextInput"
        errorMessage={formik.errors?.username || error}
        label={t('my_account_email')}
        maxLength={40}
        name="username"
        onChange={formik.handleChange}
        required
        value={formik.values?.username}
        disabled={isLoading}
      />

      <SubmitButton full type="submit" loading={loading}>
        Get Code
      </SubmitButton>
    </form>
  );
};

export default EmailForm;

function getFormValidationSchema() {
  return Yup.object().shape({
    username: Yup.string()
      .trim()
      .required(() => 'Email is required')
      .email(() => 'Email is an invalid format')
      .max(40, () => 'Email is too long'),
  });
}
