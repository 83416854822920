import { TypographyV3 as Typography } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { type ReactNode } from 'react';

import Note from '../ui/Note/Note';

export interface PageHeaderProps {
  readonly className?: string;
  readonly eyebrow?: ReactNode;
  readonly note?: ReactNode;
  readonly title: ReactNode;
}

export const PageHeader = ({
  className,
  note,
  eyebrow,
  title,
}: PageHeaderProps) => {
  return (
    <div
      className={cx(
        'flex flex-col gap-2 pb-4',
        'border-b border-gray-300',
        className,
      )}
    >
      {eyebrow ? (
        <Typography
          className="uppercase tracking-wider"
          color="gray"
          variant="eyebrow-small"
          weight="semibold"
        >
          {eyebrow}
        </Typography>
      ) : null}

      <Typography Element="h1" variant="h4">
        {title}
      </Typography>

      {note && <Note>{note}</Note>}
    </div>
  );
};

export default PageHeader;
