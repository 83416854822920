import { useState } from 'react';

import { useAuthProcessor } from '@/app/hooks';

import { CheckStatus, EmailForm, OTPForm } from '../../ui';
import EmailStatus from './EmailStatus';

interface EmailContentProps {
  readonly partner: string;
}

const EmailContent = ({ partner }: EmailContentProps): JSX.Element => {
  const [checkStatus, setCheckStatus] = useState<CheckStatus | null>(null);

  const {
    isLoading,
    step,
    error: authError,
    submitForm,
    clickLink,
  } = useAuthProcessor(partner);

  const shouldResetForm: boolean = [
    CheckStatus.ERROR_NOT_ELIGIBLE,
    CheckStatus.ERROR,
    CheckStatus.SUCCESS_EMAIL,
  ].includes(checkStatus!);

  return (
    <>
      {Boolean(checkStatus) && (
        <div className="overflow-hidden rounded-lg">
          <EmailStatus checkStatus={checkStatus!} />
        </div>
      )}

      {Boolean(checkStatus) && CheckStatus.SUCCESS_EMAIL ? (
        <OTPForm
          setCheckStatus={setCheckStatus}
          isLoading={isLoading}
          step={step}
          submitForm={submitForm}
          clickLink={clickLink}
          error={authError}
          partner={partner}
        />
      ) : (
        <EmailForm
          setCheckStatus={setCheckStatus}
          step={step}
          submitForm={submitForm}
          shouldResetForm={shouldResetForm}
          error={authError}
          partner={partner}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default EmailContent;
