import { cx } from 'class-variance-authority';

import type { LineItem } from '@/types';

import OrderLineItem from './OrderLineItem';

interface OrderItemsProps {
  readonly className?: string;
  readonly lineItems: LineItem[];
}

const OrderItems = ({ className, lineItems }: OrderItemsProps) => {
  return (
    <ol className={cx(className, 'flex h-full flex-col justify-stretch')}>
      {lineItems.map((lineItem, i: number, { length }) => {
        const first = i === 0;
        const last = i + 1 === length;

        return (
          <li
            className={cx('flex-grow flex items-center', {
              'pb-5': first,
              'py-5': !first && !last,
              'pt-5': last,
              'border-b border-gray-300': !last,
            })}
            key={`cart-line-${lineItem.title}`}
          >
            <OrderLineItem lineItem={lineItem} />
          </li>
        );
      })}
    </ol>
  );
};

export default OrderItems;
