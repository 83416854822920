import { useQuery } from '@tanstack/react-query';
import request, { gql } from 'graphql-request';

import { GraphQLEndpoint } from './endpoint';
import type { Product } from './types/graphql';

export const query = gql`
  query Products($productType: String, $env: String) {
    content(env: $env) {
      listProducts(productType: $productType) {
        id
        productKey
        title
        handle
        product_type
        colorMsgKey
        styleMsgKey
        titleMsgKey
        selectedTraits {
          name
          value
        }
        inStock
        isRing
        seoParams {
          title
          description
        }
        options {
          name
          value
        }
        images {
          originalSrc
          thumbnailSrc
          alt
        }
        wizard {
          finishStep {
            selector {
              originalSrc
            }
          }
        }
        lineItemImage {
          originalSrc
          alt
        }
        variants {
          sku
          skuCode
          id: variantId
          variantId
          productId
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export const PRODUCTS_ALL_KEY = 'All';

function withQueryKey(vars: { productType: any }) {
  return {
    key: ['useProductsData', vars.productType || PRODUCTS_ALL_KEY],
    fetch: async () => {
      const data: any = await request(GraphQLEndpoint, query);
      return data.content.listProducts as Product[];
    },
  };
}

export function useProducts({
  productType = null,
}: {
  productType?: string | null;
}) {
  const { key, fetch } = withQueryKey({
    productType,
  });

  return useQuery({
    queryKey: key,
    queryFn: fetch,
  });
}
